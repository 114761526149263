@font-face {
    font-family: 'SourceSansPro';
    font-weight: normal;
    font-style: normal;
    src: url('SourceSansPro-Regular.ttf');
}
@font-face {
    font-family: 'RobotoCondensed';
    font-weight: normal;
    font-style: normal;
    src: url('RobotoCondensed-Regular.ttf');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: normal;
    font-style: normal;
    src: url('OpenSans-Regular.ttf');
}
